import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { tenantConfigSelector } from '../../state';


interface Props {
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}


export const PrimaryButton: React.FC<Props> = ({disabled= false , className,onClick = () => {}, children}) => {
  const tenantConfig = useSelector(tenantConfigSelector);
  return (
    <StyledButton variant="none" $textColor={tenantConfig.primaryButtonTextColor} $backgroundColor={tenantConfig.primaryButtonColor} $hoverBackgroundColor={tenantConfig.primaryButtonHoverColor} className={'default btn ' + className} disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{ $textColor?: string, $backgroundColor?: string, $hoverBackgroundColor: string}>`
  color: ${props => props.$textColor};
  background-color: ${props => props.$backgroundColor};
  border-color: ${props => props.$backgroundColor};
  &:hover {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor};
    border-color: ${props => props.$hoverBackgroundColor};
  }
  &:disabled {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor};
    border-color: ${props => props.$hoverBackgroundColor};
  }
  &:focus {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor};
    border-color: ${props => props.$hoverBackgroundColor};
  }
  &:active {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor}dd; // dd is hex 86% alpha channel (RGBA) Dus 15% transparant
    border-color: ${props => props.$hoverBackgroundColor};
  }
`;
