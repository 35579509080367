import { CustomAttribute } from './CredentialSlice';
import { CredentialFormCustomAttribute } from './CredentialFormCustomAttribute';

export function getCustomAttributeValue(customAttributes: CustomAttribute[], internalName: string): string | undefined {
    const result = customAttributes.filter(customAttribute => customAttribute.internalName === internalName)
        .map(customAttribute => customAttribute.dateContent === undefined ? customAttribute.stringContent : customAttribute.dateContent);
    if ((result !== undefined) && (result.length > 0)) {
        return result[0];
    } else {
        return undefined;
    }
}

export function getCredentialFormCustomAttributeValueWithDefault(customAttributes: CredentialFormCustomAttribute[] | undefined, internalName: string, defaultValue: string | Date | File): string | Date | File {
    let result = getCredentialFormCustomAttributeValue(customAttributes, internalName);
    return result === undefined ? defaultValue : result;
}

export function getCredentialFormCustomAttributeValue(customAttributes: CredentialFormCustomAttribute[] | undefined, internalName?: string): string | Date | File | undefined {
    if (internalName === undefined) {
        return undefined;
    }
    const result = customAttributes?.filter(customAttribute => customAttribute.internalName === internalName)
        .map(customAttribute => customAttribute.dateContent === undefined ? customAttribute.stringContent : customAttribute.dateContent);
    if ((result !== undefined) && (result.length > 0)) {
        return result[0];
    } else {
        return undefined;
    }
}

export function updateCredentialFormCustomAttributeValue(customAttributes: CredentialFormCustomAttribute[] | undefined, attributeToUpdate: CredentialFormCustomAttribute ): CredentialFormCustomAttribute[] {
    const result: CredentialFormCustomAttribute[] = Object.assign([], customAttributes);
    let foundIndex = customAttributes?.findIndex(customAttribute => customAttribute.internalName === attributeToUpdate.internalName);
    if (foundIndex !== undefined && foundIndex > -1) {
        result[foundIndex] = Object.assign({}, attributeToUpdate);
        return result;
    } else {
        return result.concat(attributeToUpdate);
    }

}


