import axios from 'axios';
import { setLoading } from "../global/GlobalStateSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getVerifiablePresentation = createAsyncThunk(
    "verifiablePresentation/getVerifiablePresentation", (hash: string, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true))
        return axios.get('/api/public/vp/' + hash)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false))
            });
    }
);
