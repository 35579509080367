import React, { FC } from 'react';
import { useTranslation } from "react-i18next";

interface Props {
    status: string | undefined
}

export const StatusToText: FC<Props> = (props) => {
    const { t } = useTranslation();

    function getStatusText(status: string | undefined) {
        switch (status) {
            case "REQUESTED":
                return t('generic.status.requested.state');
            case "APPROVED":
                return t('generic.status.approved.state');
            case "REJECTED":
                return t('generic.status.rejected.state');
            case "ISSUED":
                return t('generic.status.issued.state');
            case "REVOKED":
                return t('generic.status.revoked.state');
            case "EXPIRED":
                return t('generic.status.expired.state');
            case "PAUSED":
                return t('generic.status.paused.state');
            case undefined:
                return '';
            default:
                return status;
        }

    }

    return <span>{getStatusText(props.status)}</span>;
};


