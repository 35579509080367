import React, { FC, useState } from 'react';
import { Credential } from '../../state';
import { Button, Card, CloseButton, Collapse } from 'react-bootstrap';
import { IconClipboardCopy } from '../atoms';


interface Props {
    credential: Credential | undefined
}

export const EmailAddressExtraContentLink: FC<Props> = (props) => {
    const [open, setOpen] = useState(false);
    const [textCopiedMessage, setTextCopiedMessage] = useState('');

    function onEmailDetailsCopyClicked(event: any) {
        event.preventDefault();
        if (navigator.clipboard) {
            navigator.clipboard.write([new ClipboardItem({'text/html': new Blob(['' + props.credential?.emailAddressAdditionalContent?.body], {type: 'text/html'})})])
                .then(() => setTextCopiedMessage('Text copied to clipboard'))
                .catch((error) => setTextCopiedMessage('Failed to copy text: ' + error));
            setTimeout(() => {
                setTextCopiedMessage('');
            }, 3000);
        } else {
            setTextCopiedMessage("Copy clipboard not supported")
        }
    }

    if (props.credential && props.credential?.emailAddressAdditionalContent) {
        return (
            <>
                <Button variant="link" onClick={() => setOpen(!open)} aria-expanded={open} style={{padding: 0, display: open ? 'none' : ''}}>Toon voorbeeld email</Button>
                <Collapse in={open}>
                    <Card>
                        <Card.Body>
                            <Card.Title style={{display: 'flex', justifyContent: 'end'}}><CloseButton onClick={() => setOpen(!open)}/></Card.Title>
                            <Card.Subtitle>Voorbeeld email voor laden SmartVOG in App. Bestemd voor de ontvanger van de SmartVOG</Card.Subtitle>
                            <Card.Text style={{paddingTop: 16}}>
                                <div id="example-collapse-text" dangerouslySetInnerHTML={{__html: props.credential?.emailAddressAdditionalContent.body}}/>
                            </Card.Text>
                            <Button title="Kopieer email bericht naar klemboard" variant="link" onClick={onEmailDetailsCopyClicked}><IconClipboardCopy size="24" color="#000000"/></Button>
                            <span>{textCopiedMessage}</span>
                        </Card.Body>
                    </Card>
                </Collapse>
            </>
        )
    }
    return null;
};
