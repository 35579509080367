import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { credentialsSlice, CredentialsState } from "./slices/credential";
import { presentationSlice, PresentationState } from "./slices/presentation";
import { globalSlice, GlobalState } from "./slices/global/GlobalStateSlice";
import { useDispatch } from "react-redux";
import { auditTrailSlice, AuditTrailState } from "./slices/audittrail";
import { authenticationSlice, AuthenticationState } from "./slices/authentication/AuthenticationSlice";
import { tenantConfigSlice, TenantConfigState } from './slices/tenantconfig';

export interface ApplicationState {
  globalState: GlobalState;
  credentials: CredentialsState;
  presentation: PresentationState;
  authentication: AuthenticationState;
  auditTrail: AuditTrailState;
  tentantConfig: TenantConfigState;
}

const rootReducer = combineReducers<ApplicationState>({
  globalState: globalSlice.reducer,
  credentials: credentialsSlice.reducer,
  presentation: presentationSlice.reducer,
  authentication: authenticationSlice.reducer,
  auditTrail: auditTrailSlice.reducer,
  tentantConfig: tenantConfigSlice.reducer
});

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

// @ts-ignore
export const store = configureStore<ApplicationState>({
  reducer: rootReducer,
  middleware: customizedMiddleware
});

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

