import React, { FC } from 'react';
import { Button } from "react-bootstrap";
import styled from "styled-components";
import AuthorizedElement from "./AuthorizedElement";
import { Credential } from "../../state/index";
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../atoms/PrimaryButton';

interface CredentialActionButtonProps {
    credential: Credential | undefined,
    onStatusSelected?: (credential: Credential, status: string) => void;
    buttonVariant: string;
}

export const CredentialActionButtons: FC<CredentialActionButtonProps> = (props, {onStatusSelected = () => {} }) => {
    const { t } = useTranslation();

    function renderStatusButton(status: string, index: number) {
        function getStatusActionText(nextStatus: string) {
            switch (status) {
                case "REVOKED": { return t('generic.status.action.revoke');}
                case "REJECTED": { return t('generic.status.action.reject');}
                case "ISSUED": {
                    if (props.credential!.status === 'PAUSED') {
                        return t('generic.status.action.deblock');
                    } else {
                        return t('generic.status.action.issue');
                    }
                }
                case "APPROVED": { return t('generic.status.action.approve');}
                case "PAUSED": { return t('generic.status.action.pause');}
            }
            return "Unknown status " + nextStatus;
        }

        function getStatusActionRole(nextStatus: string) {
            switch (status) {
                case "REVOKED": { return "REVOKE";}
                case "REJECTED": { return "REJECT";}
                case "ISSUED": { return "ISSUE";}
                case "APPROVED": { return "APPROVE";}
                case "PAUSED": { return "PAUSE";}
            }
            return "Unknown status " + nextStatus;
        }

        function buttonClicked(event: any) {
            props.onStatusSelected?.(props.credential!, status);
            event.preventDefault();
        }

        function getButton(status: string, index: number) {
            console.log('QQQ props.buttonVariant: ', props.buttonVariant);
            if (props.buttonVariant === "primary") {
                return (<PrimaryButton key={index} className="mr-1 mb-1" onClick={buttonClicked}><Label>{getStatusActionText(status)}</Label></PrimaryButton>);
            } else {
                return (<Button variant={props.buttonVariant} key={index} className="mr-1 mb-1" onClick={buttonClicked}><Label>{getStatusActionText(status)}</Label></Button>);
            }
        }
        return (<AuthorizedElement key={index} roles={[getStatusActionRole(status)]}>
            {getButton(status, index)}
        </AuthorizedElement>);
    }

    if (props.credential === undefined) {
        return (<span/>);
    }
    return (
        <div className="d-flex flex-wrap justify-content-start">
            {props?.credential?.allowedNextStatusses?.map((status: string, index: number) => renderStatusButton(status, index))}
        </div>
    )
};

const Label = styled.div`
    width: 90px;
`;



