import React, { FC } from 'react';
import { HeaderContentLayout } from '../templates';
import { CredentialFormContent, CredentialFormHeader } from "../organisms";

interface Props {}

export const CredentialFormPage: FC<Props> = () => {
  return (
    <HeaderContentLayout
      header={<CredentialFormHeader />}
      content={<CredentialFormContent/>}
    />
  );
};
