import React, { FC, ReactNode } from 'react';
import styled from "styled-components";

export interface StackProps {
    children: ReactNode;
}

export const Stack: FC<StackProps> = (props) => {
    return (
        <TopElement>
            {props.children?.slice(0).reverse().map((child, index) => (
                <ChildElement key={index}>{child}</ChildElement>
            ))}
        </TopElement>
    )
};

const TopElement = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
`

const ChildElement = styled.div`
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
`
