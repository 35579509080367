import React, { FC } from 'react';
import { useSelector } from "react-redux";
import { authenticationSelector } from "../../state/slices/authentication";

interface Props {
    organisationId: number | string | undefined;
}

export const OrganisationName: FC<Props> = ({organisationId}) => {
    const authentication = useSelector(authenticationSelector);
    var elements = authentication.organisations.filter((organisation) => organisation.id === organisationId);
    var name = (elements.length === 1) ? elements[0].name : 'Onbekend';

    return <span>{name}</span>;
};


