import React, { FC } from 'react';
import { Credential, CredentialTypeAttributeConfigItem } from '../../state';
import { OrganisationName } from '../atoms/OrganisationName';
import { DateToText, StatusToText } from '../atoms';
import { getCustomAttributeValue } from '../../state/slices/credential/CustomAttributeHelper';
import { AuthenticatedImage } from '../atoms/AuthenticatedImage';

interface Props {
    attributeConfig: CredentialTypeAttributeConfigItem;
    credential: Credential | undefined;
}

export const CustomAttributeToText: FC<Props> = (props) => {

    function renderColumn(attributeConfig: CredentialTypeAttributeConfigItem, credential: Credential) {
        if (attributeConfig.isFixedAttribute === true) {
            return renderFixedColumn(attributeConfig, credential);
        } else    {
            return renderCustomAtttibuteColumn(attributeConfig, credential);
        }
    }

    function renderFixedColumn(attributeConfig: CredentialTypeAttributeConfigItem, credential: Credential) {
        switch (attributeConfig.internalName) {
            case "organisation_id":
                return (<OrganisationName organisationId={credential?.organisation}/>);
            case "valid_from":
                return (<DateToText date={credential?.validFrom}/>);
            case "valid_until":
                return (<DateToText date={credential?.validUntil}/>);
            case "status":
                return (<StatusToText status={credential?.status}/>);
            case "email_address":
                return (<span>{credential?.emailAddress}</span>);
            case "photo":
                return (<AuthenticatedImage height={300} src={'/api/manage/credentials/photo/' + credential?.externalKey}/>);
            default:
                return (<span>Unknown fixed colum {attributeConfig.internalName}</span>);
        }
    }

    function renderCustomAtttibuteColumn(attributeConfig: CredentialTypeAttributeConfigItem, credential: Credential) {
        if (credential === undefined) {
            return (<span></span>);
        }
        const value = getCustomAttributeValue(credential.customAttributes, attributeConfig.internalName);
        switch (attributeConfig.dataType) {
            case "TEXT":
                return (<span>{value}</span>);
            case "DATE":
                return (<DateToText date={value}/>);
            case "IMAGE":
                return (<AuthenticatedImage height={300} src={'/api/manage/credentials/photo/' + credential?.externalKey}/>)
            case "ORGANISATION":
                return (<OrganisationName organisationId={value}/>);
            default:
                return (<span></span>);
        }
    }

    if (props.credential) {
        return <span>{renderColumn(props.attributeConfig, props.credential)}</span>;
    } else {
        return null;
    }
};


