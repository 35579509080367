import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DateToText, TitleText } from '../../atoms';
import { Col, Container, Row } from "react-bootstrap";
import { ErrorAlert, LoadingContainer } from "../../molecules";
import { getVerifiablePresentation, presentationStateSelector } from "../../../state/slices/presentation";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../state";


interface Props {
}


export const VerifiablePresentationContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    let { hash } = useParams();

    useEffect(() => {
        dispatch(getVerifiablePresentation(hash));
    }, [dispatch, hash]);

    const presentationState = useSelector(presentationStateSelector);
    return (
        <Container className="px-5">
            <LoadingContainer isLoading={presentationState.loading} hasItems={presentationState.presentation !== undefined}>
                <ErrorAlert userFriendlyError={presentationState.error ? "De QR code is verlopen." : undefined} show={presentationState.error !== undefined} dismissible={false}/>
                <TitleText>Geregistreerde BOA</TitleText>
                <Row>
                    <Col>BOA nummer:</Col>
                    <Col>{presentationState.presentation?.idNumber}</Col>
                </Row>
                <Row>
                    <Col>Domein:</Col>
                    <Col>{presentationState.presentation?.domain}</Col>
                </Row>
                <Row>
                    <Col>Werkgever:</Col>
                    <Col>{presentationState.presentation?.employer}</Col>
                </Row>
                <Row>
                    <Col>Gebied:</Col>
                    <Col>{presentationState.presentation?.locality}</Col>
                </Row>
                <Row>
                    <Col>Geldig tot:</Col>
                    <Col><DateToText date={presentationState.presentation?.validUntil}/></Col>
                </Row>
            </LoadingContainer>
        </Container>
    );
};
