import React, { FC } from 'react';

interface Props {
    date: string | undefined
}

export const DateToText: FC<Props> = (props) => {


    if ((props.date === undefined) || (props.date === null)) {
        return (<span/>);
    }

    let dDate = new Date(props.date);
    var dd = dDate.getDate();
    var mm = dDate.getMonth() + 1;
    var yyyy = dDate.getFullYear();

    return (<span>{dd}&#8209;{mm}&#8209;{yyyy}</span>);
};


