import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCredential, getCredential, getCredentials, startIssueCredential, updateCredentialStatus } from "./CredentialApi";
import { CredentialSearchForm } from './CredentialSearchForm';

export interface Credential {
    externalKey: string;
    fullName: string;
    givenName: string;
    familyName: string;
    additionalName: string;
    photoUrl: string;
    domain: string;
    idNumber: string;
    organisation: number;
    locality: string;
    validFrom: string;
    validUntil: string;
    status: string;
    emailAddress: string;
    emailAddressAdditionalContent: EmailAddressAdditionalContent | undefined;
    allowedNextStatusses: string[];
    customAttributes: CustomAttribute[]
}

export interface EmailAddressAdditionalContent {
    subject: string;
    body: string;
}

export interface CustomAttribute {
    internalName: string;
    stringContent: string;
    dateContent: string;
}

export interface CredentialsState {
    loading: boolean;
    error: string | undefined;
    list: Credential[];
    single: Credential | undefined;
    currentRequestId: string | undefined;
    credentialSearchForm: CredentialSearchForm;
}

const INITIAL_STATE: CredentialsState = {
    loading: false,
    error: undefined,
    list: [],
    single: undefined,
    currentRequestId: undefined,
    credentialSearchForm: new CredentialSearchForm()
};

export const credentialsSlice = createSlice({
    name: 'credentials',
    initialState: INITIAL_STATE,
    reducers: {
        updateCredentialSearchFormState: (state: CredentialsState, action: PayloadAction<CredentialSearchForm>) => {
            var newCredentialSearchForm = Object.assign({}, action.payload);
            return {
                ...state,
                credentialSearchForm: newCredentialSearchForm,
            };
        },
    },
    extraReducers: {
        [getCredentials.pending.type]: (state: CredentialsState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined,
                offerUrl: undefined,
                currentRequestId: action.meta.requestId
            };
        },
        [getCredentials.fulfilled.type]: (state: CredentialsState, action) => {

            if (state.currentRequestId === action.meta.requestId) {
                var newList = (action.payload.number === 0) ? [] : Object.assign([], state.list);
                newList.push.apply(newList, action.payload.content);
                var newCredentialSearchForm = Object.assign({}, state.credentialSearchForm, {
                    page: action.payload.number,
                    isLast: action.payload.last
                });
                return {
                    ...state,
                    loading: false,
                    error: undefined,
                    offerUrl: undefined,
                    list: newList,
                    credentialSearchForm: newCredentialSearchForm
                };
            } else {
                return state;
            }
        },
        [getCredentials.rejected.type]: (state: CredentialsState, action) => {
            return {
                ...state,
                loading: false,
                offerUrl: undefined,
                error: action.error.message,
            };
        },
        [getCredential.pending.type]: (state: CredentialsState, action) => {
            return {
                ...state,
                single: (action.meta.arg.externalKey === state.single?.externalKey) ? state.single : undefined,
                loading: true,
                error: undefined,
            };
        },
        [getCredential.fulfilled.type]: (state: CredentialsState, action: PayloadAction<Credential>) => {
            return {
                ...state,
                loading: false,
                error: undefined,
                single: Object.assign({}, action.payload),
            };
        },
        [getCredential.rejected.type]: (state: CredentialsState, action) => {
            return {
                ...state,
                loading: false,
                error: action.error.message,
            };
        },
        [addCredential.pending.type]: (state: CredentialsState, action: PayloadAction<void>) => {
            return {
                ...state,
                error: undefined,
            };
        },
        [addCredential.fulfilled.type]: (state: CredentialsState, action: PayloadAction<Credential>) => {
            return {
                ...state,
                list: state.list.concat(action.payload),
            };
        },
        [addCredential.rejected.type]: (state: CredentialsState, action) => {
            return {
                ...state,
                error: action.error.message,
            };
        },
        [updateCredentialStatus.pending.type]: (state: CredentialsState, action: PayloadAction<Credential>) => {
            return {
                ...state,
                error: undefined,
            };
        },
        [updateCredentialStatus.fulfilled.type]: (state: CredentialsState, action: PayloadAction<Credential>) => {
            return {
                ...state,
                list: state.list.map((item) => {
                    if (item.externalKey !== action.payload.externalKey) {
                        // This isn't the item we care about - keep it as-is
                        return item
                    }
                    // Otherwise, this is the one we want - return an updated value
                    return {
                        ...item,
                        ...action.payload
                    }
                })
            };
        },
        [updateCredentialStatus.rejected.type]: (state: CredentialsState, action: PayloadAction<string>) => {
            return {
                ...state,
                error: action.payload,
                // list: [],
            };
        },
    }
});


export const {updateCredentialSearchFormState} = credentialsSlice.actions;

