import React, { FC, useState } from 'react';
import { Alert } from "react-bootstrap";
import { Collapsable } from "./Collapsable";

interface Props {
    userFriendlyError: string | undefined
    errorDetails?: string | undefined
    show: boolean
    dismissible?: boolean
}

export const ErrorAlert: FC<Props> = ({userFriendlyError, errorDetails, show, dismissible= true}) => {
    const [showAlert, setShowAlert] = useState(show);
    if (userFriendlyError && showAlert) {
        return <Alert key={userFriendlyError} variant='danger' onClose={() => setShowAlert(false)} dismissible={dismissible}>
            {userFriendlyError}
            {errorDetails &&
                <Collapsable>
                    <div>{errorDetails}</div>
                </Collapsable>
            }
        </Alert>
    } else {
        return <span/>;
    }
};
