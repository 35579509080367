import { createSlice } from '@reduxjs/toolkit';
import { getTenantConfig } from "./TenantConfigApi";

export interface CredentialTypeConfig {
    credentialType: string;
    attributeConfig: CredentialTypeAttributeConfigItem[];
    translations: Translation[];
}

export interface CredentialTypeAttributeConfigItem {
    internalName: string;
    translationKey: string;
    dataType: string;
    dataLength: number;
    required: boolean;
    showInFilter: boolean;
    filterDisplayOrder: number;
    showInList: boolean;
    listDisplayOrder: number;
    showInForm: boolean;
    formDisplayOrder: number;
    isFixedAttribute: boolean;
    displayPriority: string;
    formMarginAfter: number;
}

export interface Translation {
    textKey: string;
    textValue: string;
}

export interface TenantConfig {
    colorHeaderPrimary: string;
    colorHeaderSecondary: string;
    colorHeaderText: string;
    colorCredentialListFilterBackgroundColor: string;
    logoPosition: string;
    primaryButtonColor: string;
    primaryButtonHoverColor: string;
    primaryButtonTextColor: string;
}

export interface TenantConfigState {
    loading: boolean;
    error: string | undefined;
    tenantConfig: TenantConfig;
    credentialTypeConfig: CredentialTypeConfig[];
}

const INITIAL_STATE: TenantConfigState = {
    loading: true,
    error: undefined,
    tenantConfig: {
        colorHeaderPrimary: "#FFFFFF",
        colorHeaderSecondary: "#FFFFFF",
        colorHeaderText: "#000000",
        colorCredentialListFilterBackgroundColor: "#FFFFFF",
        logoPosition: 'center',
        primaryButtonColor: '#007bff',
        primaryButtonHoverColor: '#007bff',
        primaryButtonTextColor: '#000000'
    },
    credentialTypeConfig: [{
        credentialType: "",
        attributeConfig: [],
        translations: [ // Initial content to show before texts from server are retrieved.
            {textKey: "generic.error.retrievingData", textValue: "Er is is mis gegaan bij het ophalen van de data. Probeer het later nog een keer."},
            {textKey: "generic.button.cancel", textValue: "Annuleren"},
            {textKey: "status.changedialog.action.issue", textValue: "Weet u zeker dat u de pas wilt deblokkeren?"},
            {textKey: "generic.imageinput.dragText", textValue: "Sleep foto naar dit vak of klik hier om een foto te selecteren"},
            {textKey: "generic.loading", textValue: "Bezig met ophalen van de gegevens..."},
            {textKey: "menuitem.home", textValue: "Home"},
            {textKey: "credential.list.noDataFound", textValue: "Geen passen gevonden"},
            {textKey: "credential.issueDialog.title", textValue: "Installeren pas op telefoon"},
            {textKey: "generic.status.paused.state", textValue: "Geblokkeerd"},
            {textKey: "generic.status.requested.state", textValue: "Aangevraagd"},
            {textKey: "generic.status.approved.state", textValue: "Goedgekeurd"},
            {textKey: "status.changedialog.action.pause", textValue: "Weet u zeker dat u de pas wilt blokkeren?"},
            {textKey: "credential.form.button.save", textValue: "Opslaan"},
            {textKey: "generic.status.rejected.state", textValue: "Afgekeurd"},
            {textKey: "generic.status.action.revoke", textValue: "Intrekken"},
            {textKey: "generic.audittrail.date", textValue: "Datum"},
            {textKey: "generic.audittrail.newStatus", textValue: "Nieuwe status"},
            {textKey: "status.changedialog.action.revoke", textValue: "Weet u zeker dat u de pas wilt intrekken?"},
            {textKey: "generic.status.issued.state", textValue: "Uitgegeven"},
            {textKey: "generic.audittrail.linktext.show", textValue: "Toon historie"},
            {textKey: "generic.audittrail.linktext.hide", textValue: "Verberg historie"},
            {textKey: "generic.status.action.pause", textValue: "Blokkeren"},
            {textKey: "status.changedialog.action.reject", textValue: "Weet u zeker dat u de pas wilt afkeuren?"},
            {textKey: "credential.issueDialog.body", textValue: "Open de Insigne App op de telefoon en scan onderstaande QR code:"},
            {textKey: "generic.status.action.issue", textValue: "Uitgeven"},
            {textKey: "generic.status.action.request", textValue: "Aanvragen"},
            {textKey: "generic.imageinput.crop", textValue: "Bijsnijden"},
            {textKey: "status.changedialog.action.approve", textValue: "Weet u zeker dat u de pas wilt goedkeuren?"},
            {textKey: "generic.status.revoked.state", textValue: "Ingetrokken"},
            {textKey: "generic.audittrail.user", textValue: "Gebruiker"},
            {textKey: "status.changedialog.remark", textValue: "Toelichting"},
            {textKey: "generic.status.expired.state", textValue: "Verlopen"},
            {textKey: "generic.audittrail.organisation", textValue: "Organisatie"},
            {textKey: "generic.status.action.approve", textValue: "Goedkeuren"},
            {textKey: "menuitem.logoff", textValue: "Uitloggen"},
            {textKey: "credential.list.title", textValue: "Overzicht BOA passen"},
            {textKey: "credential.list.button.add.title", textValue: "Nieuwe pas"},
            {textKey: "credential.issueDialog.remarks", textValue: "Toelichting"},
            {textKey: "generic.audittrail.noHistoryFound", textValue: "Nog geen history gevonden"},
            {textKey: "credential.form.new.title", textValue: "Nieuwe pas"},
            {textKey: "generic.button.ok", textValue: "OK"},
            {textKey: "generic.status.action.deblock", textValue: "Deblokkeren"},
            {textKey: "menuitem.logon", textValue: "Inloggen"},
            {textKey: "generic.audittrail.remarks", textValue: "Toelichting"},
            {textKey: "status.changedialog.title", textValue: "Bevestigen"},
            {textKey: "credential.list.numberOfItemsFound", textValue: "Totaal aantal passen gevonden: {{count}}"},
            {textKey: "generic.status.action.reject", textValue: "Afkeuren"}
        ]
    }]

};

export const tenantConfigSlice = createSlice({
    name: 'tenantConfig',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: {
        [getTenantConfig.pending.type]: (state: TenantConfigState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        },
        [getTenantConfig.fulfilled.type]: (state: TenantConfigState, action) => {
            return {
                ...state,
                loading: false,
                error: undefined,
                tenantConfig: Object.assign({}, action.payload.tenantConfig),
                credentialTypeConfig: Object.assign([], action.payload.credentialTypeConfigs),
            };
        },
        [getTenantConfig.rejected.type]: (state: TenantConfigState, action) => {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        },
    }
});

