import React, { FC } from 'react';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";


interface DateInputProps {
    onChange?: (date: Date) => void;
    value: Date | undefined;
}

export const DateInput: FC<DateInputProps> = ({value, onChange = () => {}}) => {
    return (
        <MyDatePicker className="form-control" placeholderText="dd-mm-yyyy" selected={value} onChange={onChange} dateFormat="dd-MM-yyyy" showYearDropdown/>
    );
}

const MyDatePicker = styled(DatePicker)`
  max-width: 120px;
`;


