import { CredentialTypeAttributeConfigItem } from '../tenantconfig';
import { getCredentialFormCustomAttributeValue } from './CustomAttributeHelper';
import { CredentialFormCustomAttribute } from './CredentialFormCustomAttribute';
import { formatDateForTransport } from '../TransportUtils';
import isEmail from 'validator/lib/isEmail';

export class CredentialForm {
    credentialType?: string;
    photo?: File;
    organisationId: number = 0;
    validFrom: Date | undefined;
    validUntil: Date | undefined;
    emailAddress: string = '';
    userRemarks: string = '';
    customAttributes: CredentialFormCustomAttribute[] = [];

    static isValid(credentialForm: CredentialForm, attributeConfig: CredentialTypeAttributeConfigItem[]): boolean {
        function toCamelCase(str: string): string {
            str = str.replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
            return str.substr(0, 1).toLowerCase() + str.substr(1);
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        function isRequired(attributeConfig: CredentialTypeAttributeConfigItem[], internalName: string): boolean {
            let config = attributeConfig.find(config => toCamelCase(config.internalName) === toCamelCase(internalName));
            if (config) {
                return config.required;
            } else {
                return false;
            }
        }

        if (isRequired(attributeConfig, 'validFrom') && !containsValue(credentialForm.validFrom)) {
            console.log('validFrom not yet valid');
            return false;
        }

        if (isRequired(attributeConfig, 'validUntil') && !containsValue(credentialForm.validUntil)) {
            console.log('validUntil not yet valid');
            return false;
        }

        if (containsValue(credentialForm.emailAddress) && !(isEmail(credentialForm.emailAddress))) {
            console.log('emailAddress not valid');
            return false;
        }

        if (isRequired(attributeConfig, 'emailAddress') && !containsValue(credentialForm.emailAddress)) {
            console.log('validUntil not yet valid');
            return false;
        }

        if (isRequired(attributeConfig, 'photo') && !containsValue(credentialForm.photo)) {
            console.log('photo not yet valid');
            return false;
        }

        if (isRequired(attributeConfig, 'organisation_id') && !containsValue(credentialForm.organisationId)) {
            console.log('organisation_id is not yet valid');
            return false;
        }

        if (isRequired(attributeConfig, 'userRemarks') && !containsValue(credentialForm.userRemarks)) {
            console.log('userRemarks is not yet valid');
            return false;
        }

        let requiredCustomAttributes = attributeConfig
            .filter(att => att.required)
            .filter(att => !att.isFixedAttribute);

        for (let i = 0; i < requiredCustomAttributes.length; i++) {
            const customAttributeConfig = requiredCustomAttributes[i];
            if (!containsValue(getCredentialFormCustomAttributeValue(credentialForm.customAttributes, customAttributeConfig.internalName))) {
                console.log(customAttributeConfig.internalName + ' is not yet valid');
                return false;
            }
        }
        return true;
    }

    static toFormData(credentialForm: CredentialForm) {
        let data = new FormData();
        if (credentialForm.photo) {
            data.append("photo", credentialForm.photo, credentialForm.photo.name);
        }
        data.append("credentialType", credentialForm.credentialType!);
        data.append("organisation", credentialForm.organisationId?.toString());
        data.append("validFrom", formatDateForTransport(credentialForm.validFrom));
        data.append("validUntil", formatDateForTransport(credentialForm.validUntil));
        data.append("emailAddress", credentialForm.emailAddress);
        data.append("userRemarks", credentialForm.userRemarks);
        credentialForm.customAttributes.forEach((customAttribute, index) => {
            data.append("customAttributes[" + index + "].internalName", customAttribute.internalName);
            if (customAttribute.stringContent !== undefined) {
                data.append("customAttributes[" + index + "].stringContent", customAttribute.stringContent);
            } else if (customAttribute.dateContent !== undefined) {
                data.append("customAttributes[" + index + "].dateContent", formatDateForTransport(customAttribute.dateContent));
            }

        })
        return data;
    }
}
