// Selectors
import { createSelector } from '@reduxjs/toolkit';

import { ApplicationState } from '../..';

export const presentationStateSelector = (state: ApplicationState) => state.presentation

export const presentationSelector = createSelector(presentationStateSelector, (state) => state.presentation);

// export const tasksListTodoSelector = createSelector(tasksListSelector, (list: Task[]) =>
//   list.filter((item) => !item.done),
// );
//
// export const tasksListDoneSelector = createSelector(tasksListSelector, (list: Task[]) =>
//   list.filter((item) => item.done),
// );
