import React, { FC } from 'react';
import { Navbar, NavDropdown, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { globalStateLoadingSelector, tenantConfigSelector } from "../../state";
import { useKeycloak } from "@react-keycloak/web";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
    menuItems: MenuItem[];
    hideLogin: boolean;
}

export class MenuItem {
    label: String;
    href: string;

    constructor(label: String, href: string) {
        this.label = label;
        this.href = href;
    }
}

export const NavigationBar: FC<Props> = (props) => {
    const { keycloak } = useKeycloak()
    const { t } = useTranslation();

    const handleLogInOut = () => {
        if (keycloak.authenticated) {
            keycloak.logout()
        } else {
            keycloak.login()
        }
    }
    const getUsername = () => {
        return keycloak.authenticated && keycloak.tokenParsed && (keycloak.tokenParsed?.given_name?.length > 0 ? keycloak.tokenParsed?.given_name : keycloak.tokenParsed?.family_name);
    }

    const getLogInOutText = () => {
        return keycloak.authenticated ? t('menuitem.logoff') : t('menuitem.logon')
    }
    const tenantConfig = useSelector(tenantConfigSelector);
    const loadingSelector = useSelector(globalStateLoadingSelector);
    let spinner;
    if (loadingSelector) {
        spinner = <Spinner animation="border" variant="light"/>;
    }
    return (
        <div>
            <StyledNavbar $primaryColor={tenantConfig.colorHeaderPrimary} $secondaryColor={tenantConfig.colorHeaderSecondary}>
                {props.menuItems.map((menuItem, index) => (
                    <Navbar.Brand key="index" >
                        <Link className="navbar-brand" to={menuItem.href}><StyledText $textColor={tenantConfig.colorHeaderText}>{menuItem.label}</StyledText></Link>
                    </Navbar.Brand>
                ))}
                {props.hideLogin ? null: keycloak.authenticated ?
                <Navbar.Collapse className="justify-content-end">
                    <StyledNavDropdown title={"Hi "+getUsername()} id="userMenu" $textColor={tenantConfig.colorHeaderText}>
                        <NavDropdown.Item onClick={handleLogInOut}>{getLogInOutText()}</NavDropdown.Item>
                    </StyledNavDropdown>
                    {spinner}
                </Navbar.Collapse> :
                    <Navbar.Brand onClick={handleLogInOut} key="login">
                        <StyledText $textColor={tenantConfig.colorHeaderText}>{getLogInOutText()}</StyledText>
                    </Navbar.Brand>
                }
            </StyledNavbar>
        </div>
    )
        ;
};

const StyledNavbar = styled(Navbar)<{$primaryColor: string, $secondaryColor: string}>`
  background-color: ${props => props.$primaryColor};
  border-bottom: 10px solid ${props => props.secondaryColor};
`;

const StyledNavDropdown = styled(NavDropdown)<{$textColor: string}>`
  color: ${props => props.$textColor};
  .nav-link {
    color: ${props => props.$textColor};
  }
`;

const StyledText = styled.span<{$textColor: string}>`
    color: ${props => props.$textColor};
`;
