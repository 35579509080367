import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { tenantConfigSelector } from '../../state';


interface Props {
  value: string;
  disabled: boolean;
  className: string;
}

export const PrimarySubmitButton: React.FC<Props> = ({value, disabled= false , className}) => {
  const tenantConfig = useSelector(tenantConfigSelector);
  return (
    <StyledInput type="submit" $textColor={tenantConfig.primaryButtonTextColor} $backgroundColor={tenantConfig.primaryButtonColor} $hoverBackgroundColor={tenantConfig.primaryButtonHoverColor} className={'default btn ' + className} value={value} disabled={disabled}/>
  );
};

const StyledInput = styled.input<{ $textColor?: string, $backgroundColor?: string, $hoverBackgroundColor: string}>`
  color: ${props => props.$textColor};
  background-color: ${props => props.$backgroundColor};
  border-color: ${props => props.$backgroundColor};
  &:hover {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor};
    border-color: ${props => props.$hoverBackgroundColor};
  }
  &:disabled {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor};
    border-color: ${props => props.$hoverBackgroundColor};
  }
  &:focus {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor};
    border-color: ${props => props.$hoverBackgroundColor};
  }
  &:active {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor}dd; // dd is hex 86% alpha channel (RGBA) Dus 15% transparant
    border-color: ${props => props.$hoverBackgroundColor};
  }
`;
