// Selectors
import { CredentialTypeAttributeConfigItem, TenantConfigState } from './TenantConfigSlice';
import { ApplicationState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';

export const attributeConfigStateSelector = (state: ApplicationState) => state.tentantConfig;

// For now we only support one CredentialType per tenant. Otherwise UI updates/designs are needed. Moved to a seperate story.
export const credentialTypeConfigSelector = (state: ApplicationState) => state.tentantConfig.credentialTypeConfig[0];

export const attributeConfigListSelector = createSelector(attributeConfigStateSelector, (state: TenantConfigState) => {
    if ((state.credentialTypeConfig === undefined) || (state.credentialTypeConfig.length === 0)) {
        return [];
    } else {
        return state.credentialTypeConfig[0]
            .attributeConfig
            .filter(attribute => attribute.showInList === true)
            .sort((a, b) => a.listDisplayOrder - b.listDisplayOrder);
    }
});

export const attributeConfigFilterSelector = createSelector(attributeConfigStateSelector, (state: TenantConfigState) => {
    if ((state.credentialTypeConfig === undefined) || (state.credentialTypeConfig.length === 0)) {
        return [];
    } else {
        return state.credentialTypeConfig[0]
            .attributeConfig
            .filter(attribute => attribute.showInFilter === true)
            .sort((a, b) => a.filterDisplayOrder - b.filterDisplayOrder);
    }
});

export const attributeConfigFormSelector = createSelector(attributeConfigStateSelector, (state: TenantConfigState) => {
    if ((state.credentialTypeConfig === undefined) || (state.credentialTypeConfig.length === 0)) {
        return new Array<CredentialTypeAttributeConfigItem>();
    } else {
        return state.credentialTypeConfig[0]
            .attributeConfig
            .filter(attribute => attribute.showInForm === true)
            .sort((a, b) => a.formDisplayOrder - b.formDisplayOrder);
    }
});

export const translationSelector = createSelector(attributeConfigStateSelector, (state: TenantConfigState) => {
    if ((state.credentialTypeConfig === undefined) || (state.credentialTypeConfig.length === 0)) {
        return [];
    } else {
        return state.credentialTypeConfig[0].translations;
    }
});

export const tenantConfigSelector = createSelector(attributeConfigStateSelector, (state: TenantConfigState) => {
    return state.tenantConfig;
});

