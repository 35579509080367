import React, { FC } from 'react';
import { MenuItem, NavigationBar } from "../NavigationBar";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

interface Props {
}

export const CredentialsListHeader: FC<Props> = () => {
    const { t } = useTranslation();

    return (
        <Container className="px-0 mb-4">
            <NavigationBar menuItems={[new MenuItem(t('menuitem.home'), "/")]} hideLogin={false}/>
        </Container>
    );
};
