import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { CredentialFormPage, CredentialsListPage, VerifiablePresentationPage } from './components/pages';
import { Container } from "react-bootstrap";
import { Provider } from "react-redux";
import { store } from "./state";
import { CredentialShowPage } from "./components/pages/CredentialShowPage";
import { AuthenticationProvider } from "./components/molecules/AuthenticationProvider";
import { AppInit } from "./components/AppInit";
import { I18n } from './components/I18n';

function App() {
    return (
            <Provider store={store}>
                <AuthenticationProvider>
                    <AppInit/>
                    <I18n/>
                    <Router>
                        <Container fluid className="px-0">
                            <Switch>
                                <Route exact path="/">
                                    <CredentialsListPage/>
                                </Route>
                                <Route path="/vp/:hash">
                                    <VerifiablePresentationPage />
                                </Route>
                                <Route path="/vc/new">
                                    <CredentialFormPage/>
                                </Route>
                                <Route path="/vc/:externalKey/show" >
                                    <CredentialShowPage />
                                </Route>
                            </Switch>
                        </Container>
                    </Router>
                </AuthenticationProvider>
            </Provider>
    );
}

export default App;
