import React, { FC, useState } from 'react';
import {
    attributeConfigFilterSelector,
    AuthenticationState,
    CredentialFormCustomAttribute,
    CredentialSearchForm,
    credentialSearchFormSelector,
    CredentialTypeAttributeConfigItem,
    credentialTypeConfigSelector,
    getCredentials,
    tenantConfigSelector,
    updateCredentialsSearchForm,
    useAppDispatch
} from "../../../state";
import styled from "styled-components";
import { Form } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";
import { DateInput } from "../../molecules";
import { useSelector } from "react-redux";
import { authenticationSelector } from "../../../state/slices/authentication";
import { useKeycloak } from "@react-keycloak/web";
import { getCredentialFormCustomAttributeValue, updateCredentialFormCustomAttributeValue } from '../../../state/slices/credential/CustomAttributeHelper';
import { useTranslation } from 'react-i18next';

interface Props {
}


export const CredentialsListFilter: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const [searchTimeout, setSearchTimeout] = useState(0);
    const {keycloak} = useKeycloak();
    const authentication = useSelector(authenticationSelector);
    const attributeConfig = useSelector(attributeConfigFilterSelector);
    const credentialSearchForm = useSelector(credentialSearchFormSelector);
    const tenantConfig = useSelector(tenantConfigSelector);
    const credentialTypeConfig = useSelector(credentialTypeConfigSelector);

    const { t } = useTranslation();

    let statusses = [
        {value: "REQUESTED", label: t('generic.status.requested.state')},
        {value: "APPROVED", label: t('generic.status.approved.state')},
        {value: "REJECTED", label: t('generic.status.rejected.state')},
        {value: "ISSUED", label: t('generic.status.issued.state')},
        {value: "PAUSED", label: t('generic.status.paused.state')},
        {value: "EXPIRED", label: t('generic.status.expired.state')},
        {value: "REVOKED", label: t('generic.status.revoked.state')}
    ]

    function setFormValue(param: any) {
        let newCredentialSearchForm = Object.assign({}, credentialSearchForm, param);
        search(newCredentialSearchForm);
    }

    function setFormValueForCustomAttribute(internalName: string, value: string) {
        let newCustomAttributes = updateCredentialFormCustomAttributeValue(credentialSearchForm.customAttributes, {internalName: internalName, stringContent: value} as CredentialFormCustomAttribute);
        setFormValue({customAttributes: newCustomAttributes});
    }

    function setFormDateValueForCustomAttribute(internalName: string, value: Date) {
        let newCustomAttributes = updateCredentialFormCustomAttributeValue(credentialSearchForm.customAttributes, {internalName: internalName, dateContent: value} as CredentialFormCustomAttribute);
        setFormValue({customAttributes: newCustomAttributes});
    }

    function search(credentialSearchForm: CredentialSearchForm) {
        var newCredentialSearchForm = Object.assign({}, credentialSearchForm, {
            page: 0, isLast: false,
            credentialType: credentialTypeConfig.credentialType
        });
        dispatch(updateCredentialsSearchForm({credentialSearchForm: newCredentialSearchForm}));
        if (searchTimeout) clearTimeout(searchTimeout);
        setSearchTimeout(setTimeout(() => {
            dispatch(getCredentials({credentialSearchForm: newCredentialSearchForm, token: keycloak.token}));
        }, 400));
    }

    function setStatusValue(event: any) {
        var isChecked = event.target.checked;
        var item = event.target.value;
        let statusList = Object.assign([], credentialSearchForm.status);
        if (isChecked) {
            statusList.push(item);
        } else {
            const index = statusList.indexOf(item, 0);
            if (index > -1) {
                statusList.splice(index, 1);
            }
        }
        let newCredentialSearchForm = Object.assign({}, credentialSearchForm, {status: statusList});
        search(newCredentialSearchForm);
    }

    function setOrganisation(event: any) {
        var isChecked = event.target.checked;
        var item = event.target.value;
        let organisationList = Object.assign([], credentialSearchForm.organisations);
        if (isChecked) {
            organisationList.push(item);
        } else {
            const index = organisationList.indexOf(item, 0);
            if (index > -1) {
                organisationList.splice(index, 1);
            }
        }
        let newCredentialSearchForm = Object.assign({}, credentialSearchForm, {organisations: organisationList});
        search(newCredentialSearchForm);
    }

    function handleSubmit(event: any) {
        dispatch(getCredentials({credentialSearchForm: credentialSearchForm, token: keycloak.token}));
        event.preventDefault();
    }


    function isStatusChecked(item: string) {
        let checked = (credentialSearchForm.status.indexOf(item, 0) > -1);
        return checked;
    }

    function isOrganisationChecked(item: number) {
        let checked = (credentialSearchForm.organisations.indexOf(item.toString(), 0) > -1);
        return checked;
    }


    function renderColumn(attributeConfig: CredentialTypeAttributeConfigItem, credentialSearchForm: CredentialSearchForm) {
        if (attributeConfig.isFixedAttribute === true) {
            return renderFixedColumn(attributeConfig, credentialSearchForm);
        } else    {
            return renderCustomAttibuteColumn(attributeConfig, credentialSearchForm);
        }
    }

    function renderFixedColumn(attributeConfig: CredentialTypeAttributeConfigItem, credentialSearchForm: CredentialSearchForm) {
        switch (attributeConfig.internalName) {
            case "status":
                return statusses.map(item => (
                    <Form.Check type="checkbox" key={item.value} value={item.value} id={item.value}
                                label={item.label}
                                checked={isStatusChecked(item.value)} onChange={setStatusValue}/>
                ));
            case "organisation_id":
                return authentication.organisations.map(item => (
                        <Form.Check type="checkbox" key={item.id} value={item.id} id={item.id.toString()}
                                    label={item.name}
                                    checked={isOrganisationChecked(item.id)} onChange={setOrganisation}/>
                    ));
            case "email_address":
            return (
                    <Form.Control type="email" value={credentialSearchForm.emailAddress} onChange={e => setFormValue({emailAddress: e.target.value})}/>
                    );
            case "valid_from":
                return (<div className="d-flex justify-content-between flex-wrap">
                        <DateInput value={credentialSearchForm.validFromFrom}
                                   onChange={date => setFormValue({validFromFrom: date})}/>
                        <DateInput value={credentialSearchForm.validFromTo}
                                   onChange={date => setFormValue({validFromTo: date})}/>
                    </div>
                );
            case "valid_until":
                return (<div className="d-flex justify-content-between flex-wrap">
                    <DateInput value={credentialSearchForm.validUntilFrom}
                               onChange={date => setFormValue({validUntilFrom: date})}/>
                    <DateInput value={credentialSearchForm.validUntilTo}
                               onChange={date => setFormValue({validUntilTo: date})}/>
                </div>);
            default:
                return (<span>Unknown fixed colum {attributeConfig.internalName}</span>);
        }
    }

    function renderCustomAttibuteColumn(attributeConfig: CredentialTypeAttributeConfigItem, credentialSearchForm: CredentialSearchForm) {
        const value = getCredentialFormCustomAttributeValue(credentialSearchForm.customAttributes, attributeConfig.internalName);
        switch (attributeConfig.dataType) {
            case "TEXT":
                return (<Form.Control value={value}
                            onChange={e => setFormValueForCustomAttribute(attributeConfig.internalName, e.target.value)}/>
                );
            case "DATE":
                return (<DateInput value={value}
                                   onChange={date => setFormDateValueForCustomAttribute(attributeConfig.internalName, date)}/>);
            default:
                return (<span></span>);
        }
    }


    function mustRenderColum(attributeConfig: CredentialTypeAttributeConfigItem, authentication: AuthenticationState) : boolean{
        if (attributeConfig.internalName === "organisation_id") {
            return authentication.organisations.length > 1;
        } else {
            return true;
        }
    }

    return (
        <FilterContainer className="p-2" $backgroundColor={tenantConfig.colorCredentialListFilterBackgroundColor}>
            <FilterContent>
                <Form onSubmit={handleSubmit}>
                    {attributeConfig
                        .filter(attributeConfig => mustRenderColum(attributeConfig, authentication))
                        .map(attributeConfig =>
                        (<div key={attributeConfig.internalName} className="mb-3">
                            <label className="form-label">{t(attributeConfig.translationKey)}</label>
                            {renderColumn(attributeConfig, credentialSearchForm)}
                        </div>)
                    )}
                </Form>
            </FilterContent>
        </FilterContainer>
    );


};

const FilterContainer = styled.div<{$backgroundColor: string}>`
  background-color: ${props => props.$backgroundColor};
  // background-color: #d9ebf7;
  display: flex;
  flex-flow: column;
  height: 100%;
`;
const FilterContent = styled.div`
   flex: 1 1 auto;
`;

