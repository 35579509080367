export function formatDateForTransport(date: Date | undefined) {
    if ((date === undefined) || (date === null)) {
        return "";
    }
    let month = '' + (date.getMonth() + 1)
    let day = '' + date.getDate()
    let year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}
