import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Credential, errorSelector, getCredential, singleCredentialSelector } from "../../../state/slices/credential";
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, Row } from "react-bootstrap";
import { attributeConfigFormSelector, globalStateLoadingSelector } from "../../../state";
import { StatusToText, TitleText } from "../../atoms";
import { CredentialActionButtons, EmailAddressExtraContentLink, ErrorAlert, LoadingContainer, StatusChangeConfirmDialog } from "../../molecules";
import { IssueCardDialog } from "../../molecules/IssueCardDialog";
import { AuditTrailList } from "../AuditTrailList/AuditTrailList";
import { Collapsable } from "../../molecules/Collapsable";
import { useKeycloak } from "@react-keycloak/web";
import { CustomAttributeToText } from '../../molecules/CustomAttributeToText';
import { useTranslation } from 'react-i18next';

interface Props {
}

class ConfirmDialogDetails {
    credential: Credential | undefined
    nextStatus: string | undefined;
    show: boolean = false;
}


export const CredentialShowContent: FC<Props> = () => {
    let {externalKey} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const attributeConfig = useSelector(attributeConfigFormSelector);
    const {keycloak} = useKeycloak();
    const [confirmDialogDetails, setConfirmDialogDetails] = useState(new ConfirmDialogDetails());
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getCredential({externalKey:externalKey, token: keycloak.token!}));
    }, [dispatch, externalKey, keycloak.token]);

    const [showIssueDialog, setShowIssueDialog] = useState(false);

    const singleCredential = useSelector(singleCredentialSelector);
    const loadingSelector = useSelector(globalStateLoadingSelector);
    const error = useSelector(errorSelector);


    function onStatusClicked(credential: Credential, nextStatus: string) {
        if (credential.status !== 'PAUSED' && nextStatus === "ISSUED") {
            setShowIssueDialog(true);
        } else {
            let dialogDetails = new ConfirmDialogDetails();
            dialogDetails.credential = credential;
            dialogDetails.nextStatus = nextStatus;
            dialogDetails.show = true;
            setConfirmDialogDetails(dialogDetails);
        }
    }

    function onStatusChangeConfirmDialogStatusActionConfirmed(credential: Credential, nextStatus: string, userRemarks: string) {
        let dialogDetails = new ConfirmDialogDetails();
        dialogDetails.show = false;
        setConfirmDialogDetails(dialogDetails);
        history.push('/');
    }

    function onStatusActionCancelled() {
        let dialogDetails = new ConfirmDialogDetails();
        dialogDetails.show = false;
        setConfirmDialogDetails(dialogDetails);
    }

    function onIssueCardDialogCardIssued(credential: Credential) {
        setShowIssueDialog(false);
        history.push('/');
    }

    function onIssueCardDialogCancelled(credential: Credential) {
        dispatch(getCredential({externalKey:externalKey, token: keycloak.token!}));
        setShowIssueDialog(false);
    }

    return (
        <div className="px-5 pb-3">
            <LoadingContainer isLoading={loadingSelector} hasItems={singleCredential !== undefined}>
                <ErrorAlert
                    userFriendlyError={t('generic.error.retrievingData')}
                    errorDetails={error}
                    show={error !== undefined}/>
                <TitleText>{t('credential.show.title')}</TitleText>
                <Form>
                    {attributeConfig
                        .filter(attributeConfig => attributeConfig.internalName !== 'status')
                        .filter(attributeConfig => attributeConfig.internalName !== 'user_remarks')
                        .map((attributeConfig, index) =>
                            (<Form.Group as={Row} className={"pb-" + (attributeConfig.formMarginAfter + 1)} key={index}>
                            <Form.Label column sm="4">{t(attributeConfig.translationKey)}</Form.Label>
                            <Form.Label column sm="8">
                                <CustomAttributeToText attributeConfig={attributeConfig} credential={singleCredential}/>
                                {attributeConfig.internalName === 'email_address' && singleCredential?.emailAddressAdditionalContent && (
                                    <div><EmailAddressExtraContentLink credential={singleCredential}/></div>
                                )}
                            </Form.Label>
                        </Form.Group>
                        )
                    )}
                    <Form.Group as={Row} className="mb-0">
                        <Form.Label column sm="4">{t('customAttribute.displayName.status')}</Form.Label>
                        <Form.Label column sm="8">
                            <StatusToText status={singleCredential?.status}/>
                        </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="4">
                            &nbsp;
                        </Form.Label>
                        <Col sm="8" className="mt-4">
                            <CredentialActionButtons buttonVariant="primary"
                                                     credential={singleCredential} onStatusSelected={onStatusClicked}/>
                        </Col>
                    </Form.Group>
                </Form>
                <Collapsable showLinkText={t('generic.audittrail.linktext.show')} hideLinkText={t('generic.audittrail.linktext.hide')}>
                    <AuditTrailList externalKey={externalKey}/>
                </Collapsable>
                <StatusChangeConfirmDialog credential={confirmDialogDetails.credential}
                                           nextStatus={confirmDialogDetails.nextStatus}
                                           showDialog={confirmDialogDetails.show}
                                           onConfirm={onStatusChangeConfirmDialogStatusActionConfirmed}
                                           onCancel={onStatusActionCancelled}/>
                <IssueCardDialog credential={singleCredential!}
                                 showDialog={showIssueDialog && singleCredential !== undefined}
                                 onCardIssued={onIssueCardDialogCardIssued}
                                 onCancel={onIssueCardDialogCancelled}/>
            </LoadingContainer>
        </div>
    );
};
