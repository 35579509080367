import React, { FC, useState } from 'react';
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { Col, Row } from "react-bootstrap";
import { IconUpload } from "../atoms";
import { ImageCropDialog } from "./ImageCropDialog";
import { useTranslation } from 'react-i18next';

export const ImageInput: FC<ImageProps> = ({onImageSelected = () => {}}) => {
    const [imagePreview, setImagePreview] = useState("");
    const [imageFile, setImageFile] = useState<File>();
    const [showCropDialog, setShowCropDialog] = useState(false);
    const { t } = useTranslation();

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: (acceptedFiles) => {
            setShowCropDialog(true);
            setPhotoSelected(acceptedFiles[0]);
        }, accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg', '.jpeg'],
        }
    });

    function setPhotoSelected(file: File) {
        setImageFile(file);
        setShowCropDialog(true);
        onImageSelected(file);
    }

    function onCropConfirmClicked(croppedImage: File) {
        setPhotoSelected(croppedImage);
        setImagePreview(URL.createObjectURL(croppedImage));
        setShowCropDialog(false);
    }

    function onCropCancelClicked() {
        setShowCropDialog(false);
    }
    return (
        <div className="p-0">
            <Row className="">
                <Col className="">
                    <ArrowedSquare {...getRootProps()}>
                        <Row className="justify-content-center align-items-center">
                            <input {...getInputProps()} />
                            <IconUpload size="100" color="#ced4da"/>
                            <p className="text-center">{t('generic.imageinput.dragText')}</p>
                        </Row>
                    </ArrowedSquare>
                </Col>
                <Col>
                    {imageFile && (
                        <ImageCropDialog correlation="Q" title={t('generic.imageinput.crop')} body="Q" showDialog={showCropDialog}
                                         onConfirm={onCropConfirmClicked} onCancel={onCropCancelClicked} imageFile={imageFile} maxHeight={200}/>
                    )}
                    <img width="100%" src={imagePreview} alt=""/>
                </Col>
            </Row>
        </div>
);
}



interface ImageProps {
    onImageSelected?: (file: File) => void;
}

const ArrowedSquare = styled.div`
    &:focus {
        outline: none;
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    padding: 20px;
    padding-bottom: 0px;
    border: #ced4da solid 2px;

    border-style: dashed;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;*/
`;

