// Selectors
import { createSelector } from '@reduxjs/toolkit';

import { ApplicationState } from '../..';

export const credentialsStateSelector = (state: ApplicationState) => state.credentials

export const credentialsListSelector = createSelector(credentialsStateSelector, (state) => state.list);
export const singleCredentialSelector = createSelector(credentialsStateSelector, (state) => state.single);
export const errorSelector = createSelector(credentialsStateSelector, (state) => state.error);
export const credentialSearchFormSelector = createSelector(credentialsStateSelector, (state) => state.credentialSearchForm);


