import React, { FC } from 'react';
import { HeaderContentLayout } from '../templates';
import { CredentialsListContent, CredentialsListFilter, CredentialsListHeader } from '../organisms';
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";


interface Props {
}

export const CredentialsListPage: FC<Props> = () => {
    const { keycloak } = useKeycloak()
    if (!keycloak.authenticated) {
        keycloak.login();
    }
    return (
        <HeaderContentLayout
            header={<CredentialsListHeader/>}
            content={
                <Container>
                    <Row>
                        <Col className="col-sm-3 col-md-2 px-0">
                            <CredentialsListFilter/>
                        </Col>
                        <Col className="col-sm-9 col-md-10">
                            <CredentialsListContent/>
                        </Col>
                    </Row>
                </Container>
            }
        />
    );
};

