import React, { FC } from 'react';
import { HeaderContentLayout } from '../templates';
import { VerifiablePresentationContent, VerifiablePresentationHeader } from '../organisms';

interface Props {
}

export const VerifiablePresentationPage: FC<Props> = () => {
    return (
        <HeaderContentLayout
            header={<VerifiablePresentationHeader />}
            content={<VerifiablePresentationContent />}
        />
    );
};
