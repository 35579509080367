import React, { FC } from 'react';
import { NavigationBar } from "../NavigationBar";
import { Container } from "react-bootstrap";

interface Props {
}

export const VerifiablePresentationHeader: FC<Props> = () => {
    return (
        <Container className="px-0 mb-4">
            <NavigationBar menuItems={[]} hideLogin={true}/>
        </Container>
    );
};
