import React, { FC, useEffect } from 'react';
import { useAppDispatch } from "../state";
import { getUserExtrasMe } from "../state/slices/authentication";
import { useKeycloak } from "@react-keycloak/web";
import { getTenantConfig } from '../state/slices/tenantconfig';

interface Props {
}


export const AppInit: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        dispatch(getUserExtrasMe(keycloak.token));
        dispatch(getTenantConfig(keycloak.token));
    }, [dispatch, keycloak.token]);
    return (<span/>);
};
