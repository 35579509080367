import React, { FC, ReactElement } from 'react';
import { Col, Container } from "react-bootstrap";
import styled from "styled-components";
import { useSelector } from 'react-redux';
import { tenantConfigSelector } from '../../state';

interface Props {
    header: ReactElement;
    content: ReactElement;
}

export const HeaderContentLayout: FC<Props> = ({header, content}) => {
    const tenantConfig = useSelector(tenantConfigSelector);
    return (
        <div>
            {/*<Container className="px-0 text-center mb-4">*/}
            {/*    <span className="d-block d-sm-none">xs </span>*/}
            {/*    <span className="d-none d-sm-block d-md-none">sm </span>*/}
            {/*    <span className="d-none d-md-block d-lg-none">md </span>*/}
            {/*    <span className="d-none d-lg-block d-xl-none">lg </span>*/}
            {/*    <span className="d-none d-xl-block">xl </span>*/}
            {/*</Container>*/}
            <Container className={"px-0 text-" + tenantConfig.logoPosition}>
                <Col>
                    <Logo className="center-block px-2" src="/api/manage/tenantImage/logo"/>
                </Col>
            </Container>
            <Container className="px-0">
                <Container className="px-0">{header}</Container>
                <Container className="px-0">{content}</Container>
            </Container>
        </div>
    );
};

const Logo = styled.img`
  max-width: 90%;
  max-height: 90%;
`;
