import { createSlice } from '@reduxjs/toolkit';
import { getAuditTrails } from "./AuditTrailApi";

export interface AuditTrail {
    auditTimestamp: string;
    username: string;
    organisationName: string;
    newStatus: string;
    userRemark: string;
}

export interface AuditTrailState {
    loading: boolean;
    error: string | undefined;
    list: AuditTrail[];
}

const INITIAL_STATE: AuditTrailState = {
    loading: false,
    error: undefined,
    list: []
};

export const auditTrailSlice = createSlice({
    name: 'auditTrails',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: {
        [getAuditTrails.pending.type]: (state: AuditTrailState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        },
        [getAuditTrails.fulfilled.type]: (state: AuditTrailState, action) => {
            return {
                ...state,
                loading: false,
                error: undefined,
                list: Object.assign([], action.payload),
            };
        },
        [getAuditTrails.rejected.type]: (state: AuditTrailState, action) => {
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        },
    }
});

