import { Credential, updateCredentialSearchFormState } from "./CredentialSlice";
import axios from 'axios';
import { setLoading } from "../global/GlobalStateSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CredentialForm } from './CredentialForm';
import { CredentialSearchForm } from './CredentialSearchForm';

export const updateCredentialsSearchForm = createAsyncThunk(
    "credentials/updateCredentialsSearchForm", ({credentialSearchForm}: { credentialSearchForm: CredentialSearchForm }, thunkAPI) => {
        thunkAPI.dispatch(updateCredentialSearchFormState(credentialSearchForm));
        return Promise.resolve();
    }
);

export const getCredentials = createAsyncThunk(
    "credentials/getCredentials", ({
                                       credentialSearchForm,
                                       token
                                   }: { credentialSearchForm: CredentialSearchForm, token: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true))
        const requestParams = {
            page: credentialSearchForm.page,
            size: credentialSearchForm.size
        }
        const config = {
            params: requestParams,
            headers: {'Authorization': bearerAuth(token)}
        };

        var body = CredentialSearchForm.toJsonForm(credentialSearchForm);
        return axios.post('/api/manage/credentials', body, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false))
            });
    }
);

export const getCredential = createAsyncThunk(
    "credentials/getCredential", ({externalKey, token}: { externalKey: string, token: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true))
        return axios.get('/api/manage/credentials/' + externalKey, {
            headers: {'Authorization': bearerAuth(token)}
        })
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false))
            });
    }
);

export const addCredential = createAsyncThunk(
    "credentials/addCredential", ({
                                      credentialForm,
                                      token
                                  }: { credentialForm: CredentialForm, token: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));

        let data = CredentialForm.toFormData(credentialForm);

        const config = {
            headers: {'content-type': 'multipart/form-data', 'Authorization': bearerAuth(token)}
        };
        return axios.post("/api/manage/credentials", data, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false))
            });
    }
);

export const updateCredentialStatus = createAsyncThunk(
    "credentials/updateStatus", ({
                                     credential,
                                     newStatus,
                                     userRemarks,
                                     token
                                 }: { credential: Credential, newStatus: string, userRemarks: string, token: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true))
        const config = {
            headers: {'Authorization': bearerAuth(token)}
        };
        let data = new FormData();
        data.append("newStatus", newStatus);
        data.append("userRemarks", userRemarks);

        return axios.post("/api/manage/credentials/" + credential.externalKey + "/status", data, config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false))
            });
    }
);

export const startIssueCredential = createAsyncThunk(
    "credentials/startIssueCredential", ({externalKey, token}: { externalKey: string, token: string }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true))
        return axios.get('/api/manage/credentials/' + externalKey + '/issue', {
            headers: {'Authorization': bearerAuth(token)}
        })
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false))
            });
    }
);

function bearerAuth(token: string) {
    return `Bearer ${token}`
}
