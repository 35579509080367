import axios from 'axios';
import { setLoading } from "../global/GlobalStateSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getTenantConfig = createAsyncThunk(
    "tenantConfig/getTenantConfig", (token: string, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true))
        return axios.get('/api/manage/tenantconfig')
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false))
            });
    }
);
