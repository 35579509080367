import axios from 'axios';
import { setLoading } from "../global/GlobalStateSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getAuditTrails = createAsyncThunk(
    "auditTrails/getAuditTrails", ({credentialExternalKey, token}: {credentialExternalKey: string, token: string}, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true))
        const config = {
            headers: {'Authorization': bearerAuth(token)}
        };
        return axios.get('/api/manage/credentials/'+ credentialExternalKey + '/audittrail', config)
            .then(response => response.data)
            .finally(() => {
                thunkAPI.dispatch(setLoading(false))
            });
    }
);

function bearerAuth(token: string) {
    return `Bearer ${token}`
}
