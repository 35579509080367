import React, { FC, useState } from 'react';
import styled from "styled-components";


interface CollapsableProps {
    isOpened?: boolean;
    showLink?: boolean;
    showLinkText?: string;
    hideLinkText?: string;
}

export const Collapsable: FC<CollapsableProps> = ({
                                                      isOpened = false,
                                                      showLink = true,
                                                      showLinkText = 'Toon details',
                                                      hideLinkText = 'Verberg details',
                                                      children
                                                  }) => {
    const [show, setShow] = useState(isOpened);

    function toggle() {
        setShow(!show);
    }

    if (show) {
        return (
            <Div>
                <Content>
                    {children}
                </Content>
                {showLink &&
                <div>
                    <Link onClick={toggle}>{hideLinkText}</Link>
                </div>
                }
            </Div>
        );
    } else {
        return (<Div>
            {showLink &&
            <Link onClick={toggle}>{showLinkText}</Link>
            }
        </Div>)
    }
};

const Div = styled.div`
    cursor: default; 
`;

const Link = styled.a`
  color: #828282;
  font-size: 70%;
`;

const Content = styled.div`
  padding-top: 20px;
  font-size: small;
`;

