import React, { FC, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

interface Props {
    height: number
    src: string;
}

export const AuthenticatedImage: FC<Props> = ({src, height}) => {
    const [ imgSrc, setImage ] = useState('');
    const { keycloak } = useKeycloak();


    useEffect(() => {

        let isSubscribed = true;
        async function getImage(src: string) {
            const response = await axios
                .get(src, {
                    headers: { Authorization: 'Bearer ' + keycloak.token },
                    responseType: 'arraybuffer'
                });
            let data = `data:${
                response.headers["content-type"]
            };base64,${new Buffer(response.data, 'binary').toString("base64")}`;
            if (isSubscribed) {
                setImage(data);
            }
        }
        getImage(src);
        return () => (isSubscribed = false)
    }, [src, keycloak.token]);

    return <img height={height} src={imgSrc} alt={imgSrc}/>
}
