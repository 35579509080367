import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import {
    Credential,
    credentialsStateSelector,
    getCredential,
    getCredentials,
    singleCredentialSelector, startIssueCredential,
    updateCredentialStatus,
    useAppDispatch
} from "../../state";
import { useSelector } from "react-redux";
import { useInterval } from "../../utils/use_interval";
import { IconCheckMark } from "../atoms";
import { Stack } from "../atoms/Stack";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../atoms/PrimaryButton';

var QRCode = require('qrcode.react');

export interface IssueCardDialogProps {
    credential: Credential;
    showDialog: boolean;
    onCardIssued?: (credential: Credential) => void;
    onCancel?: (credential: Credential) => void;
}

export const IssueCardDialog: FC<IssueCardDialogProps> = (props) => {
        const [pollInternal, setPollInternal] = useState(2500);
        const {keycloak} = useKeycloak();
        const dispatch = useAppDispatch();
        const [userRemarks, setUserRemarks] = useState('');
        const [offerUrl, setOfferUrl] = useState('');
        const singleCredential = useSelector(singleCredentialSelector);
        const { t } = useTranslation();

        useEffect(() => {
            if (singleCredential && props.showDialog) {
                dispatch(startIssueCredential({externalKey: props.credential.externalKey, token: keycloak.token})).then((response) => {
                    setOfferUrl(response.payload);
                });
            }
         // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.showDialog]);

        useInterval(() => {
                if (props.showDialog) {
                    retrieveStatus(); // Only poll for changes when the card might be issued
                }
            }, pollInternal
        );

        function retrieveStatus() {
            dispatch(getCredential({externalKey: props.credential.externalKey, token: keycloak.token})).then((response) => {
                if (response.payload?.status === "ISSUED") {
                    setPollInternal(null);
                }
            });
        }

        function onCancelClicked() {
            props.onCancel?.(props.credential);
        }

        function onOkClicked() {
            dispatch(
                updateCredentialStatus({credential: props.credential, newStatus: "ISSUED", userRemarks: userRemarks, token: keycloak.token})
            );
            props.onCardIssued?.(props.credential);
        }

        function okEnabled(): boolean {
            return singleCredential?.status === 'ISSUED';
        }

        return (
            <Modal
                centered
                show={props.showDialog}
                onHide={onCancelClicked}
                backdrop="static"
                onEscapeKeyDown={onCancelClicked}
            >
                <Modal.Header closeButton onHide={onCancelClicked}>
                    <Modal.Title>{t('credential.issueDialog.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {t('credential.issueDialog.body')}
                    </div>
                    <div className="text-center mt-3 mb-3">
                        <Stack>
                            {singleCredential?.status === "ISSUED" &&
                            <IconCheckMark color="#00ff44"/>
                            }
                            { offerUrl && <QRCode value={offerUrl} size={300}/> }
                        </Stack>
                    </div>
                    <Form.Group controlId="userRemarks">
                        <Form.Label>{t('credential.issueDialog.remarks')}</Form.Label>
                        <Form.Control as="textarea" rows={3} maxLength={2500}
                                      onChange={e => setUserRemarks(e.target.value)}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <PrimaryButton onClick={onOkClicked} disabled={!okEnabled()}>{t('generic.button.ok')}</PrimaryButton>
                    <Button variant="secondary" onClick={onCancelClicked}>{t('generic.button.cancel')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
;

