import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BoldText, StatusToText } from '../../atoms';
import { Table } from "react-bootstrap";
import { ErrorAlert, LoadingContainer } from "../../molecules";
import { globalStateLoadingSelector } from "../../../state/slices/global/GlobalStateSelectors";
import { auditTrailSelector, getAuditTrails } from "../../../state/slices/audittrail";
import { DateTimeToText } from "../../atoms/DateTimeToText";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from 'react-i18next';

interface AuditTrailListProps {
    externalKey: string
}


export const AuditTrailList: FC<AuditTrailListProps> = (props) => {
    const dispatch = useDispatch();
    const {keycloak} = useKeycloak();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAuditTrails({credentialExternalKey: props.externalKey, token: keycloak.token!}));
    }, [dispatch, props.externalKey, keycloak.token]);

    const allAuditTrails = useSelector(auditTrailSelector);
    const loadingSelector = useSelector(globalStateLoadingSelector);

    return (
        <div>
            <LoadingContainer isLoading={loadingSelector} hasItems={allAuditTrails.list.length > 0} fullHeight={false}>
                <ErrorAlert
                    userFriendlyError={'Er is is mis gegaan bij het ophalen van de data. Probeer het later nog een keer.'}
                    errorDetails={allAuditTrails.error}
                    show={allAuditTrails.error !== undefined}
                />
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <BoldText>Historie</BoldText>
                </div>
                {allAuditTrails.list.length > 0 &&
                <Table responsive striped bordered hover>
                    <thead>
                    <tr>
                        <th>{t('generic.audittrail.date')}</th>
                        <th>{t('generic.audittrail.user')}</th>
                        <th>{t('generic.audittrail.organisation')}</th>
                        <th>{t('generic.audittrail.newStatus')}</th>
                        <th>{t('generic.audittrail.remarks')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allAuditTrails.list.map((auditTrail, index) => (
                        <tr key={index}>
                            <td><DateTimeToText date={auditTrail.auditTimestamp}/></td>
                            <td>{auditTrail.username}</td>
                            <td>{auditTrail.organisationName}</td>
                            <td><StatusToText status={auditTrail.newStatus}/></td>
                            <td>{auditTrail.userRemark}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                }
                {allAuditTrails.list.length === 0 &&
                    <div>{t('generic.audittrail.noHistoryFound')}</div>
                }
            </LoadingContainer>
        </div>
    );


};
