import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TitleText } from '../../atoms';
import { credentialSearchFormSelector, credentialsStateSelector, CredentialTypeAttributeConfigItem, credentialTypeConfigSelector, getCredentials } from '../../../state/slices';
import { Table } from "react-bootstrap";
import { CenteredMessageContainer, CredentialActionButtons, ErrorAlert, LoadingContainer, StatusChangeConfirmDialog } from "../../molecules";
import { Link, useHistory } from "react-router-dom";
import { Credential } from "../../../state/slices/credential/CredentialSlice";
import { useAppDispatch } from "../../../state";
import { useKeycloak } from "@react-keycloak/web";
import AuthorizedElement from "../../molecules/AuthorizedElement";
import InfiniteScroll from 'react-infinite-scroll-component';
import { attributeConfigListSelector } from '../../../state/slices/tenantconfig';
import { CustomAttributeToText } from '../../molecules/CustomAttributeToText';
import { useTranslation } from 'react-i18next';
import { PrimaryLinkButton } from '../../atoms/PrimaryLinkButton';

interface Props {
}


class ConfirmDialogDetails {
    credential?: Credential;
    nextStatus?: string;
    show: boolean = false;
}

export const CredentialsListContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {keycloak} = useKeycloak();


    const allCredentials = useSelector(credentialsStateSelector);
    const attributeConfig = useSelector(attributeConfigListSelector);
    const credentialTypeConfig = useSelector(credentialTypeConfigSelector);

    const [confirmDialogDetails, setConfirmDialogDetails] = useState(new ConfirmDialogDetails());
    const { t } = useTranslation();

    const credentialSearchForm = useSelector(credentialSearchFormSelector);
    useEffect(() => {
        if (credentialTypeConfig.credentialType) {
            const newForm = Object.assign({}, credentialSearchForm, {credentialType: credentialTypeConfig.credentialType});
            dispatch(getCredentials({credentialSearchForm: newForm, token: keycloak.token!}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentialTypeConfig]);


    function onStatusClicked(credential: Credential, nextStatus: string) {
        if (credential.status !== 'PAUSED' && nextStatus === "ISSUED") {
            history.push('/vc/' + credential.externalKey + '/show');
        } else {
            let dialogDetails = new ConfirmDialogDetails();
            dialogDetails.credential = credential;
            dialogDetails.nextStatus = nextStatus;
            dialogDetails.show = true;
            setConfirmDialogDetails(dialogDetails);
        }
    }

    function onStatusActionConfirmed(credential: Credential, nextStatus: string, userRemarks: string) {
        let dialogDetails = new ConfirmDialogDetails();
        dialogDetails.show = false;
        setConfirmDialogDetails(dialogDetails);
    }

    function onStatusActionCancelled() {
        let dialogDetails = new ConfirmDialogDetails();
        dialogDetails.show = false;
        setConfirmDialogDetails(dialogDetails);
    }

    function fetchNextPage() {
        var newForm = Object.assign({}, credentialSearchForm, {page: credentialSearchForm.page + 1, credentialType: credentialTypeConfig.credentialType});
        dispatch(getCredentials({credentialSearchForm: newForm, token: keycloak.token}));
    }


    function getAttributeClassName(attributeConfigItem: CredentialTypeAttributeConfigItem) {
        switch (attributeConfigItem.displayPriority) {
            case "MEDIUM":
                return "d-none d-lg-table-cell";
            case "LOW":
                return "d-none d-xl-table-cell";
            default:
                return "";
        }
    }

    function getInfiniteScrollLoaderMessage() {
        if (allCredentials.list.length > 1) { // Only show this loader when loading an additional page. Otherwise the generic first page loader is shown.
            return (<p style={{textAlign: 'center'}}>
                {t('generic.loading')}
            </p>)
        } else {
            return (<span></span>);
        }
    }
    function getInfiniteScrollEndMessage() {
        if (allCredentials.list.length > 0) {
            return (<p style={{textAlign: 'center'}}>
                {t('credential.list.numberOfItemsFound', {'count': allCredentials.list.length})}
            </p>)
        } else {
            return (<span></span>);
        }
    }

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <TitleText>{t('credential.list.title')}</TitleText>
                <AuthorizedElement roles={['REQUEST']}>
                    <PrimaryLinkButton className="default btn float-right" to="/vc/new">{t('credential.list.button.add.title')}</PrimaryLinkButton>
                </AuthorizedElement>
            </div>
            <LoadingContainer isLoading={allCredentials.loading} hasItems={allCredentials.list.length > 0}>
                <ErrorAlert
                    userFriendlyError={t('generic.error.retrievingData')}
                    errorDetails={allCredentials.error}
                    show={allCredentials.error !== undefined}/>

                <InfiniteScroll
                    dataLength={allCredentials.list.length} //This is important field to render the next data
                    next={fetchNextPage}
                    hasMore={!credentialSearchForm.isLast}
                    loader={getInfiniteScrollLoaderMessage()}
                    endMessage={getInfiniteScrollEndMessage()}>
                    {allCredentials.list.length > 0 &&
                    <Table responsive striped bordered hover>
                        <thead>
                        <tr>
                            {attributeConfig.map(attribute => <th key={attribute.internalName} className={getAttributeClassName(attribute)}>{t(attribute.translationKey)}</th>)}
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {allCredentials.list.map(credential => (
                            <tr key={credential.externalKey}>
                                {attributeConfig.map((attributeConfig, columnIndex) =>
                                    (<td key={attributeConfig.internalName} className={getAttributeClassName(attributeConfig)}>
                                        {columnIndex === 0 &&
                                        <Link to={'/vc/' + credential.externalKey + '/show'}>
                                            <CustomAttributeToText attributeConfig={attributeConfig} credential={credential}/>
                                        </Link>
                                        }
                                        {columnIndex > 0 &&
                                        <CustomAttributeToText attributeConfig={attributeConfig} credential={credential}/>
                                        }
                                    </td>)
                                )}
                                <td><CredentialActionButtons credential={credential} buttonVariant="secondary"
                                                             onStatusSelected={onStatusClicked}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    }
                </InfiniteScroll>
                {allCredentials.list.length === 0 && !allCredentials.loading &&
                <CenteredMessageContainer message={t('credential.list.noDataFound')}/>
                }

                <StatusChangeConfirmDialog credential={confirmDialogDetails.credential}
                                           nextStatus={confirmDialogDetails.nextStatus}
                                           showDialog={confirmDialogDetails.show}
                                           onConfirm={onStatusActionConfirmed} onCancel={onStatusActionCancelled}/>
            </LoadingContainer>
        </div>
    );


};
