import React, { FC, useState } from 'react';
import { addCredential, CredentialForm, CredentialFormCustomAttribute, CredentialSearchForm, errorSelector } from "../../../state/slices/credential";
import { Col, Form, Row } from 'react-bootstrap';
import { DateInput, ErrorAlert, ImageInput } from "../../molecules";
import { TitleText } from "../../atoms";
import { useHistory } from "react-router-dom";
import { attributeConfigFormSelector, CredentialTypeAttributeConfigItem, credentialTypeConfigSelector, useAppDispatch } from "../../../state";
import { useSelector } from "react-redux";
import { authenticationSelector } from "../../../state/slices/authentication";
import { OrganisationSelect } from "../../atoms/OrganisationSelect";
import AuthorizedElement from "../../molecules/AuthorizedElement";
import { useKeycloak } from "@react-keycloak/web";
import { getCredentialFormCustomAttributeValueWithDefault, updateCredentialFormCustomAttributeValue } from '../../../state/slices/credential/CustomAttributeHelper';
import { useTranslation } from 'react-i18next';
import { PrimarySubmitButton } from '../../atoms/PrimarySubmitButton';

interface Props {
}


export const CredentialFormContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [credentialForm, setCredentialForm] = useState(new CredentialForm());
    const [addPending, setAddPending] = useState(false);
    const [photo, setPhoto] = useState<File | undefined>();
    const error = useSelector(errorSelector);
    const authentication = useSelector(authenticationSelector);
    const attributeConfig = useSelector(attributeConfigFormSelector);
    const credentialTypeConfig = useSelector(credentialTypeConfigSelector);
    const {keycloak} = useKeycloak();
    const { t } = useTranslation();

    function setImageSelected(file: File): void {
        setPhoto(file);
    }

    function setFormValue(param: any) {
        console.log('QQQ param: ', param);
        setCredentialForm(Object.assign({}, credentialForm, param))
    }

    function setFormValueForCustomAttribute(internalName: string, value: string) {
        let newCustomAttributes = updateCredentialFormCustomAttributeValue(credentialForm.customAttributes, {internalName: internalName, stringContent: value} as CredentialFormCustomAttribute);
        setFormValue({customAttributes: newCustomAttributes});
    }

    function setFormDateValueForCustomAttribute(internalName: string, value: Date) {
        let newCustomAttributes = updateCredentialFormCustomAttributeValue(credentialForm.customAttributes, {internalName: internalName, dateContent: value} as CredentialFormCustomAttribute);
        setFormValue({customAttributes: newCustomAttributes});
    }

    function handleSubmit(event: any) {
        setAddPending(true);
        let formToSubmit = Object.assign({}, credentialForm, {photo: photo, credentialType: credentialTypeConfig.credentialType}) as CredentialForm;
        dispatch(addCredential({credentialForm: formToSubmit, token: keycloak.token}))
            .then((response) => {
                if (response.error === undefined) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        history.push("/")
                    }, 250);
                }
            })
            .finally(() => {
                setAddPending(false);
            });
        event.preventDefault();
    }

    // Set the initial organisation
    if (credentialForm.organisationId < 1 && authentication.user !== undefined && authentication.organisations.length > 0) {
        setFormValue({organisationId: authentication.organisations[0].id})
    }


    function isFormValid(): boolean {
        credentialForm.photo = photo;
        return CredentialForm.isValid(credentialForm, attributeConfig);
    }


    function renderColumn(attributeConfig: CredentialTypeAttributeConfigItem, credentialSearchForm: CredentialSearchForm) {
        if (attributeConfig.isFixedAttribute === true) {
            return renderFixedColumn(attributeConfig, credentialSearchForm);
        } else {
            return renderCustomAttibuteColumn(attributeConfig, credentialSearchForm);
        }
    }

    function renderFixedColumn(attributeConfig: CredentialTypeAttributeConfigItem, credentialSearchForm: CredentialSearchForm) {
        switch (attributeConfig.internalName) {
            case "organisation_id":
                return (<Col sm="8"><OrganisationSelect selectedOrganisationId={credentialForm.organisationId}
                                                        onChange={(organisation) => setFormValue({organisationId: organisation.id})}/>
                    </Col>
                );
            case "photo":
                return (<Col sm="4"><ImageInput onImageSelected={setImageSelected}/></Col>);
            case "valid_from":
                return (<Col sm="4"><DateInput value={credentialForm.validFrom} onChange={date => setFormValue({validFrom: date})}/></Col>);
            case "valid_until":
                return (<Col sm="4"><DateInput value={credentialForm.validUntil} onChange={date => setFormValue({validUntil: date})}/></Col>);
            case "email_address":
                return (<Col sm="8"><Form.Control type="email" value={credentialForm.emailAddress} onChange={e => setFormValue({emailAddress: e.target.value})}/></Col>);
            default:
                return (<span></span>);
        }
    }

    function renderCustomAttibuteColumn(attributeConfig: CredentialTypeAttributeConfigItem, credentialSearchForm: CredentialSearchForm) {
        let value = getCredentialFormCustomAttributeValueWithDefault(credentialSearchForm.customAttributes, attributeConfig.internalName, '');
        const colSize = attributeConfig.dataLength <= 30 ? "4" : "8";
        switch (attributeConfig.dataType) {
            case "TEXT":
                return (<Col sm={colSize}><Form.Control value={value}
                                                        onChange={e => setFormValueForCustomAttribute(attributeConfig.internalName, e.target.value)}/>
                    </Col>
                );
            case "DATE":
                return (<Col sm="4"><DateInput value={value} onChange={date => setFormDateValueForCustomAttribute(attributeConfig.internalName, date)}/></Col>);
            default:
                return (<span></span>);
        }
    }


    function renderLabel(attributeConfig: CredentialTypeAttributeConfigItem) {
        const requiredIndicator = (attributeConfig.required ? '*' : '');
        return (
            <Form.Label column sm="4">
                {t(attributeConfig.translationKey)}{requiredIndicator}
            </Form.Label>
        );
    }

    return (
        <Form onSubmit={handleSubmit} className="px-5">
            <ErrorAlert
                userFriendlyError={t('generic.error.retrievingData')}
                errorDetails={error}
                show={error !== undefined}/>

            <TitleText>{t('credential.form.new.title')}</TitleText>

            {attributeConfig
                .filter(item => item.internalName !== 'user_remarks')
                .map((attributeConfigItem, index) =>
                    (
                        <Form.Group as={Row} className={"pb-" + (attributeConfigItem.formMarginAfter + 1)} controlId={attributeConfigItem.internalName} key={index}>
                            {renderLabel(attributeConfigItem)}
                            {renderColumn(attributeConfigItem, credentialForm)}
                        </Form.Group>
                    )
                )}
            <Form.Group as={Row} controlId="userRemarks" className="pb-1" >
                <Form.Label column sm="4">{t('generic.audittrail.remarks')}</Form.Label>
                <Col sm="8">
                    <Form.Control as="textarea" rows={5} maxLength={2500}
                                  onChange={e => setFormValue({userRemarks: e.target.value})}/>
                </Col>
            </Form.Group>
            <AuthorizedElement roles={['REQUEST']}>
                <PrimarySubmitButton className="mt-3 default float-right" type="submit" value={t('credential.form.button.save')} disabled={addPending || !isFormValid()}/>
            </AuthorizedElement>
        </Form>
    );
};
