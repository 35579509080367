import React, { FC } from 'react';
import { DateToText } from "./DateToText";

interface Props {
    date: string | undefined
}

export const DateTimeToText: FC<Props> = (props) => {

        if ((props === undefined) || (props.date === undefined) || (props.date === null)) {
            return (<span/>);
        }

        let dDate = new Date(props.date);
        var hours = dDate.getHours();
        var minutes = ('00' + dDate.getMinutes()).slice(-2);
        var seconds = ('00' + dDate.getSeconds()).slice(-2);

        return (<span><DateToText date={props.date}/> {hours}:{minutes}:{seconds}</span>);
    }
;


