import React, { FC } from 'react';
import { useSelector } from "react-redux";
import { authenticationSelector, Organisation } from "../../state/slices/authentication";
import { Form } from "react-bootstrap";


interface Props {
    selectedOrganisationId: number;
    onChange: (organisation: Organisation) => void;
}


export const OrganisationSelect: FC<Props> = ({selectedOrganisationId, onChange = () => {
}}) => {
    const authentication = useSelector(authenticationSelector);

    function onSelectItem(id: any) {
        onChange(authentication.organisations.filter((organisation) => organisation.id === id)[0]);
    }

    return (
        <Form.Control as="select"
                      value={selectedOrganisationId}
                      onChange={e => onSelectItem(e.target.value)}
                      >
            {authentication.organisations.map((organisation) => (<option key={organisation.id} value={organisation.id}>{organisation.name}</option>))}
        </Form.Control>
    )
};


