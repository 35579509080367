import React, { FC } from 'react';

import { CredentialShowContent, CredentialShowHeader } from "../organisms";
import { HeaderContentLayout } from "../templates";

interface Props {}

export const CredentialShowPage: FC<Props> = () => {
  return (
    <HeaderContentLayout
      header={<CredentialShowHeader />}
      content={<CredentialShowContent />}
    />
  );
};
