import React from 'react';
import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { tenantConfigSelector } from '../../state';


export const PrimaryLinkButton: React.FC<LinkProps> = ({to, className, children }) => {
  const tenantConfig = useSelector(tenantConfigSelector);
  return (
    <StyledLink $textColor={tenantConfig.primaryButtonTextColor} $backgroundColor={tenantConfig.primaryButtonColor} $hoverBackgroundColor={tenantConfig.primaryButtonHoverColor} className={'default btn ' + className}  to={to}>
      {children}
    </StyledLink>
  );
};

const StyledLink = styled(Link)<{ $textColor?: string, $backgroundColor?: string, $hoverBackgroundColor: string}>`
  color: ${props => props.$textColor};
  background-color: ${props => props.$backgroundColor};
  border-color: ${props => props.$backgroundColor};

  &:hover {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor};
    border-color: ${props => props.$hoverBackgroundColor};
  }

  &:disabled {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor};
    border-color: ${props => props.$hoverBackgroundColor};
  }

  &:focus {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor};
    border-color: ${props => props.$hoverBackgroundColor};
  }

  &:active {
    color: ${props => props.$textColor};
    background-color: ${props => props.$hoverBackgroundColor}dd; // dd is hex 86% alpha channel (RGBA) Dus 15% transparant
    border-color: ${props => props.$hoverBackgroundColor};
  }
`;
