import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getVerifiablePresentation } from "./PresentationApi";

export interface Presentation {
    domain: string;
    idNumber: string;
    employer: string;
    locality: string;
    validUntil: string;
}

export interface PresentationState {
    loading: boolean;
    error: string | undefined;
    presentation: Presentation | undefined;
}

const INITIAL_STATE: PresentationState = {
    loading: false,
    error: undefined,
    presentation: undefined
};

export const presentationSlice = createSlice({
    name: 'presentation',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: {
        [getVerifiablePresentation.pending.type]: (state: PresentationState, action: PayloadAction<string>) => {
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        },
        [getVerifiablePresentation.fulfilled.type]: (state: PresentationState, action: PayloadAction<any>) => {
            return {
                ...state,
                error: undefined,
                loading: false,
                presentation: mapToPresentation(action.payload),
            };
        },
        [getVerifiablePresentation.rejected.type]: (state: PresentationState, action: any) => {
            return {
                ...state,
                error: action.error.message,
                loading: false,
                // list: [],
            };
        },
    }
});

function mapToPresentation(payload: any): Presentation {
    let vc = payload.verifiableCredential[0].credentialSubject;
    return {
        idNumber: vc.idNumber,
        domain: vc.domain,
        employer: vc.employer,
        locality: vc.locality,
        validUntil: vc.validUntil,
    };
}
