import { CredentialFormCustomAttribute } from './CredentialFormCustomAttribute';
import { formatDateForTransport } from '../TransportUtils';


export class CredentialSearchForm {
    credentialType: string = '';
    fullName: string = '';
    organisations: number[] = [];
    domain: string = '';
    idNumber: string = '';
    locality: string = '';
    emailAddress: string = '';
    validFromFrom: Date | undefined;
    validFromTo: Date | undefined;
    validUntilFrom: Date | undefined;
    validUntilTo: Date | undefined;
    status: string[] = ['REQUESTED', 'APPROVED'];
    page: number = 0; // pagination
    size: number = 30; // pagination
    isLast: boolean = false;
    customAttributes?: CredentialFormCustomAttribute[] = [];

    static toJsonForm(credentialSearchForm: CredentialSearchForm): any {
        var body = Object.assign({}, credentialSearchForm) as any;
        body.validFromFrom = formatDateForTransport(body.validFromFrom);
        body.validFromTo = formatDateForTransport(body.validFromTo);
        body.validUntilFrom = formatDateForTransport(body.validUntilFrom);
        body.validUntilTo = formatDateForTransport(body.validUntilTo);

        function hasAttributeValue(customAttribute: CredentialFormCustomAttribute) {
            return (((customAttribute.stringContent !== undefined) && (customAttribute.stringContent !== '')) || (customAttribute.dateContent !== undefined));
        }

        var newCustomAttributes = body.customAttributes
            .filter((customAttribute: CredentialFormCustomAttribute) => {return hasAttributeValue(customAttribute)})
            .map((customAttribute: CredentialFormCustomAttribute) => {
            var newAttribute = {internalName: customAttribute.internalName} as any;
            if (customAttribute.stringContent !== undefined) {
                newAttribute.stringContent = customAttribute.stringContent;
            } else if (customAttribute.dateContent !== undefined) {
                newAttribute.dateContent = formatDateForTransport(customAttribute.dateContent);
            }
            return newAttribute;
        });
        body.customAttributes = newCustomAttributes;
        return body;
    }
}
