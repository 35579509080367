import React, { FC, useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { Credential, updateCredentialStatus, useAppDispatch } from "../../state";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../atoms/PrimaryButton';

export interface ConfirmDialogProps {
    credential: Credential | undefined;
    nextStatus: string | undefined;
    showDialog: boolean;
    onConfirm?: (credential: Credential, nextStatus: string, userRemarks: string) => void;
    onCancel?: (credential: Credential, nextStatus: string) => void;
}

export const StatusChangeConfirmDialog: FC<ConfirmDialogProps> = (props) => {
    const dispatch = useAppDispatch();
    const { keycloak } = useKeycloak();
    const [userRemarks, setUserRemarks] = useState('');
    const { t } = useTranslation();

    if (props.nextStatus === undefined) {
        return null;
    }
    function onConfirmClicked() {
        const allowedNextStatusses = ["APPROVED", "REJECTED", "REVOKED", "PAUSED"];
        if (props.credential!.status === 'PAUSED') {
            allowedNextStatusses.push("ISSUED");
        }
        if (allowedNextStatusses.includes(props.nextStatus!)) {
            dispatch(
                updateCredentialStatus({credential: props.credential!, newStatus: props.nextStatus!, userRemarks: userRemarks, token: keycloak.token!})
            );
        }
        props.onConfirm?.(props.credential!, props.nextStatus!, userRemarks);
    }

    function onCancelClicked() {
        props.onCancel?.(props.credential!, props.nextStatus!);
    }

    function createConfirmBodyText(): string {
        switch (props.nextStatus) {
            case "APPROVED":
                return t('status.changedialog.action.approve');
            case "REJECTED":
                return t('status.changedialog.action.reject');
            case "REVOKED":
                return t('status.changedialog.action.revoke');
            case "PAUSED":
                return t('status.changedialog.action.pause');
            case "ISSUED":
                return t('status.changedialog.action.issue');
        }
        return ""
    }

    if (!props.credential || !keycloak.token) {
        return null;
    }

    return (
        <Modal
            centered
            show={props.showDialog}
            backdrop="static"
            onEscapeKeyDown={onCancelClicked}
            >
            <Modal.Header closeButton onHide={onCancelClicked}>
                <Modal.Title>{t('status.changedialog.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{createConfirmBodyText()}</div>
                <br/><br/>
                <Form.Group controlId="userRemarks">
                    <Form.Label>{t('status.changedialog.remark')}</Form.Label>
                    <Form.Control as="textarea" rows={5} maxLength={2500} onChange={e => setUserRemarks(e.target.value)}/>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <PrimaryButton onClick={onConfirmClicked}>OK</PrimaryButton>
                <Button variant="secondary" onClick={onCancelClicked}>Annuleren</Button>
            </Modal.Footer>
        </Modal>
    );
};

