import React, { FC } from 'react';
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';


interface LoadingProps {
    isLoading?: boolean;
    hasItems?: boolean;
    fullHeight?: boolean;
}

export const LoadingContainer: FC<LoadingProps> = ({
                                                       isLoading = false,
                                                       hasItems = false,
                                                       fullHeight = true,
                                                       children
                                                   }) => {
    const { t } = useTranslation();

    function getClassNames() {
        var classNames = " justify-content-center align-items-center";
        if (fullHeight) {
            classNames = 'h-100 ' + classNames;
        }
        return classNames;
    }

    function getLoadingPage() {
        return (
            <Container>
                <Row className={getClassNames()}>
                    <span>
                        {t('generic.loading')}
                    </span>
                </Row>
            </Container>
        )
    }

    if (isLoading && !hasItems) {
        return getLoadingPage();
    } else {
        return children;
    }
};

const Container = styled.div`
  height: 50vh;
`;
